import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import hauntedlegacy from "../../Assets/Projects/hauntedlegacy.png";
import detect from "../../Assets/Projects/detect.png";
import lucidity from "../../Assets/Projects/lucidity.png";
import charm from "../../Assets/Projects/charm.jpg";
import mitis from "../../Assets/Projects/mitis.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Container>
        <h1 className="projectHeader">
           <strong className="dark-purple">Portfolio </strong>
        </h1>
        <p className="projectSubtext">
          Some selected projects developed (excluding this website)
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>

          {/* Lucidity */}
          <Col md={6} className="project-card">
            <ProjectCard
              imgPath={detect}
              isFigma={true}
              title="detect"
              description="Simple entertainment finder for movie, tv-shows and shortfilms"
              date="August 2024 - September 2024 "
              design="UX Design"
              platform="Figma | Lo-Fi/Hi-Fi/Functional Prototype"
              viewLink="/project/detect"
              link="https://www.figma.com/proto/TzzVwbMxWrUCm9x1SZc4As/Entertainment-Finder-App---High-Fidelity-Prototype?node-id=1-2&t=Hyyy5rj6akQ96xRL-1&scaling=scale-down&content-scaling=fixed&page-id=0%3A1&starting-point-node-id=1%3A2"
            />
          </Col>

          {/* Lucidity */}
          <Col md={6} className="project-card">
            <ProjectCard
              imgPath={lucidity}
              isFigma={true}
              title="lucidity"
              description="Simplifier for artificial intelligence (AI) audio and image generation"
              date="June 2024 - July 2024 "
              design="UX Design"
              platform="Figma | Lo-Fi/Hi-Fi/Functional Prototype"
              viewLink="/project/lucidity"
              link="https://www.figma.com/design/qB2uTnjfbWm4joEt67plnY/lucidity?t=IppItIHVDaflwOFH-1"
            />
          </Col>

          {/* Haunted Legacy */}
          <Col md={6} className="project-card">
            <ProjectCard
              imgPath={hauntedlegacy}
              isFigma={false}
              title="Haunted Legacy"
              description="Narrative driven virtual reality (VR) horror game with detailed interface and multiple endings"
              date="August 2023 - June 2024 "
              design="VR Game Design"
              platform="Unity | C# | Figma | Photoshop"
              viewLink="/project/hauntedlegacy"
              link="https://robin-boe.github.io/HauntedLegacy/"
            />
          </Col>

          {/* CHARM */}
          <Col md={6} className="project-card">
            <ProjectCard
              imgPath={charm}
              isFigma={false}
              title="CHARM"
              description="Small full-body augmented reality (AR) app for iOS"
              date="September 2022 - November 2022"
              design="App Development"
              platform="Unity | C# | Figma | Xcode"
              viewLink="/project/charm" 
              link="https://github.com/Robin-Boe/MIXMAGICAR"          
            />
          </Col>

          {/* MITIS */}
          <Col md={6} className="project-card">
            <ProjectCard
              imgPath={mitis}
              isFigma={true}
              title="MITIS"
              description="Musician booking app for businesses and musicians"
              date="February 2021 - April 2021"
              design="UX Design"
              platform="Figma | Lo-Fi/Hi-Fi/Functional Prototype"
              viewLink="/project/mitis"
              link="https://www.figma.com/design/Yq1EAT97cEePTcP5n2oe4E/Hi-Fi-Prototype?node-id=0-1&t=iqWtFx0rH0EwbrZc-1"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
